.title {
  font-weight: var(--font-weight-secondary-bold);
  font-family: var(--font-family-secondary);
  color: var(--palette-text-100);
  font-size: var(--font-size-3);
  line-height: 1.125;

  padding-bottom: var(--spacing-3);
}

.orSeparator {
  padding-bottom: var(--spacing-5);
}

.loginButton {
  padding-bottom: var(--spacing-4);
}
