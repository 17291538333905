.icon {
  margin-right: var(--spacing-2);

  height: 1rem;
  overflow: hidden;
}

.button {
  border-style: solid;
  border-width: 1px;

  color: var(--palette-text-000);

  &:not(.disabled),
  &:not(:disabled) {
    background-color: #3B5998;
    border-color: #3B5998;
  }

  &:hover, &.mouseHover {
    background-color: #4467b0;
    border-color: #4467b0;
  }
  &:active, &.active {
    background-color: #6583c3;
    border-color: #6583c3;
  }
  &.disabled {
    color: var(--palette-grey-400);
    background-color: var(--palette-grey-200);
    border-color: var(--palette-grey-200);
  }
}
