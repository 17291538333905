.root {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-2);
  line-height: 1.14;
}

.success {
  color: var(--palette-success-500);
}

.warning {
  color: var(--palette-error-500);
  font-weight: var(--font-weight-primary-semi-bold);
}

.error {
  color: var(--palette-error-700);
  font-weight: var(--font-weight-primary-semi-bold);
}
