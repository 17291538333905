$decisionHistoryBorderColor: var(--palette-grey-300);

.root {
  margin: 0;
  padding: 0;
  list-style-type: none;

  & > * {
    border-bottom: 1px solid $decisionHistoryBorderColor;
  }
  & > *:last-child {
    border-bottom: 0;
  }
}
