.root {
  position: relative;
  padding: var(--spacing-4);
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: var(--round-corners);
  background-color: $colors-pure-white;
  /*
    Fallback begin

    Unfortunately Firefox / IE does not support
    `word-break: break-word` yet.
  */
  word-break: break-all;
  /* Fallback end */
  word-break: break-word;
}
