.popover {
  width: 350px;
}

.historyIcon {
  color: var(--palette-text-100);
  margin-right: 10px;
}

.historyIcon span {
  display: flex;
}
