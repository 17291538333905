.textField {
  width: calc(45 * var(--mini-unit));
}

.adornment {
  padding: 0 var(--mini-unit);
}

.selectField {
  min-width: calc(16 * var(--mini-unit));
}
