$sideNavItemColor: var(--palette-text-100);
$sideNavBorderColor: var(--palette-grey-300);
$sideNavIndicatorColor: $colors-coral-500;
$sideNavActiveColor: var(--palette-text-500);

.link {
  color: $sideNavItemColor;
  font-family: var(--font-family-primary);
  font-size: var(--font-size-4);
  line-height: 1.11;
  font-weight: var(--font-weight-primary-regular);
  display: inline-block;
  padding: var(--spacing-2) var(--spacing-2)
    var(--spacing-2) calc(var(--spacing-3) + 4px);
  border-left: 1px solid $sideNavBorderColor;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    border-left: 2px solid $sideNavIndicatorColor;
    padding-left: calc(var(--spacing-3) + 3px);
    color: $sideNavActiveColor;
  }
}

.linkActive, .linkActive:hover {
  border-left: 4px solid $sideNavIndicatorColor;
  padding-left: var(--spacing-3);
  color: $sideNavActiveColor;
  font-weight: var(--font-weight-primary-bold);
}
