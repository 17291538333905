.button {
  width: 100%;
  justify-content: space-between;
  padding: 0;
  line-height: 1.14;
}

.arrowsIcon {
  align-self: baseline;
}
