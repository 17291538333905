.root {
  margin-bottom: var(--spacing-2);

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  width: 100%;
}

.usernameEmail {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  margin-bottom: var(--spacing-1);
}

.username {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-3);
  line-height: 1.25;

  color: var(--palette-text-500);

  margin-right: var(--spacing-1);
}

.email {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-3);
  line-height: 1.25;

  color: var(--palette-text-500);
}

.removeIcon {
  margin-right: var(--spacing-1);
}

.removeButton {
  font-size: var(--font-size-2);
  line-height: 1;
}
