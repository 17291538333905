$moderated-by: var(--palette-text-100);
$moderated-by-username: var(--palette-text-900);

.moderatedBy {
  font-size: var(--font-size-1);
  font-weight: var(--font-weight-primary-semi-bold);
  font-family: var(--font-family-primary);
  line-height: 1.33;
  color: $moderated-by;
  text-transform: uppercase;
}

.moderatedByUsername {
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-secondary-bold);
  font-family: var(--font-family-secondary);
  line-height: 1.14;
  color: $moderated-by-username;
  text-align: center;
  padding-top: 1px;
}
