$conversationModalHighlightBackground: $colors-teal-100;
$conversationModalCommentText: var(--palette-text-500);
.root {
  flex: 1;
  padding: 0 var(--spacing-2);
}

.line {
  border-color: var(--palette-grey-400);
}

.adornments {
  margin-right: var(--spacing-2);
}

.circle {
  margin-top: var(--spacing-2);
  margin-bottom: var(--spacing-2);
}

.highlightedCircle {
  margin-top: var(--spacing-1);
}

.highlighted {
  background-color: $conversationModalHighlightBackground;
  padding: var(--spacing-2);
  padding-top: var(--spacing-1);
  margin-bottom: var(--spacing-2);
}

.commentText {
  color: $conversationModalCommentText;
}

.showReplies {
  padding-left: var(--spacing-2);
}

.commentWrapper {
  flex: 1;
}

.rejectButton {
  height: fit-content;
  margin-top: var(--spacing-1);
  &:disabled {
    background-color: var(--palette-error-500) !important;
    opacity: 1 !important;
  }
}
