$userMenuButtonColor: var(--palette-text-100);

.button {
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 15px;
}

.icon {
  color: $userMenuButtonColor;
}

.buttonText {
  font-family: var(--font-family-secondary);
  color: $userMenuButtonColor;
  font-size: var(--font-size-4);
  margin-left: 2px;
  margin-right: var(--spacing-1);
}
