.label {
  padding-bottom: var(--spacing-2);
}

.keySection {
  flex-grow: 1;
  min-width: 50px;

  padding-right: var(--spacing-3);
}

.statusSection {
  margin-right: var(--spacing-3);
}

.secretSection {
  flex-grow: 1;
  min-width: 50px;
}

.action {
  margin-right: var(--spacing-1)
}
