.text {
  padding-top: var(--spacing-2);
  padding-bottom: var(--spacing-2);

  font-size: var(--font-size-3);
  font-weight: var(--font-weight-primary-regular);
  font-family: var(--font-family-primary);
  line-height: 1.45;
  color: var(--palette-text-900);
  font-style: italic;
}