.root {
  margin-top: var(--spacing-2);
}

.dropdown {
  max-height: 15em;
  width: calc(20 * var(--mini-unit));
  overflow: auto;
}

.wrapper {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.button {
  color: var(--palette-text-500) !important;
  border-width: 0;
  width: calc(20 * var(--mini-unit));
  margin-right: calc(var(--spacing-1) / 2);
  padding-left: var(--spacing-1);
  font-size: var(--font-size-3);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-bold);
  line-height: 1.2;
  justify-content: flex-start;
  align-items: center;
  background: var(--palette-background-input);
}

.filterInput {
  resize: none;
  height: 100%;
  width: auto;
  overflow: hidden;
  margin-left: var(--spacing-1);
  margin-right: var(--spacing-1);
}

.filterInput > textarea {
  resize: none;
  overflow: hidden;
  sizing: border-box;
  color: var(--palette-grey-400);
}

.buttonIconLeft {
  width: 20px;
}

.buttonIconRight {
  margin-left: auto;
  margin-right: var(--spacing-1);
  width: 16px;
}

.buttonIconLeft,
.buttonIconRight {
  /* adjust for button line-height being > 1 */
  margin-top: -2px;
}
