$input-value: var(--palette-text-500);
$input-border: var(--palette-grey-500);
$input-background: var(--palette-background-input);

$input-regular-focused-border: $colors-teal-500;
$input-streamBlue-focused-border: var(--palette-primary-500);
$input-error-border: var(--palette-error-500);

$input-disabled-background: var(--palette-background-input-disabled);
$input-disabled-value: var(--palette-text-input-disabled);
$input-disabled-border: var(--palette-grey-400);

.root {
  display: flex;
  width: 100%;
  align-items: center;
}

.input {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-3);
  line-height: 1;

  display: block;
  width: 100%;
  align-self: stretch;
  margin-top: 0;
  margin-bottom: 0;

  padding: var(--spacing-2);

  background-color: $input-background;
  color: $input-value;

  border-radius: var(--round-corners);
  border-style: solid;

  &:read-only,
  &:disabled {
    background-color: $input-disabled-background;
    border-color: $input-disabled-border;
    color: $input-disabled-value;
  }
  &:focus {
    outline: none;
  }
}

.colorRegular {
  border: 1px solid $input-border;
  &:focus {
    border: 1px solid $input-regular-focused-border;
    box-shadow: inset 0px 0px 0px 1px $input-regular-focused-border;
  }
}

.colorStreamBlue {
  border: 1px solid $input-border;
  &:focus {
    border: 1px solid $input-streamBlue-focused-border;
    box-shadow: inset 0px 0px 0px 1px $input-streamBlue-focused-border;
  }
}

.colorError {
  border: 2px solid $input-error-border;
}
