.root {
  width: 214px;
  padding: var(--spacing-3);
}

.sectionLabel {
  display: block;
  font-family: var(--font-family-primary);
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-primary-bold);
}

.optionAction {
  padding: 0;
  margin: var(--spacing-1) 0;
  width: fit-content;
}

.rejectButton {
  margin-bottom: var(--spacing-2);
}
