$suspend-modal-text: var(--palette-text-500);

.radioButton {
  margin: 0 var(--spacing-1) 0 0 !important;
}

.textArea {
  width: 100%;
  box-sizing: border-box;
  height: calc(12 * var(--mini-unit));
  padding: calc(0.5 * var(--mini-unit));
}

.header {
  margin: 0 0 var(--spacing-3) 0 !important;
}

.subTitle {
  font-size: var(--font-size-4);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  line-height: 1.11;
  color: $suspend-modal-text;
  margin: 0;
}

.footer {
  margin-top: var(--spacing-3);
}
