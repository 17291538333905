.valueBoxValue {
  font-size: var(--font-size-7);
  font-weight: var(--font-weight-primary-semi-bold);
  line-height: 0.57;
}

.valueBoxName {
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-primary-semi-bold);
}
