.button {
  padding-top: var(--spacing-1);
  padding-bottom: var(--spacing-1);
}

.usernameEmail {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.username {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-3);
  line-height: 1.25;

  color: var(--palette-text-500);

  margin-right: var(--spacing-1);
}

.email {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-3);
  line-height: 1.25;

  color: var(--palette-text-500);
}
