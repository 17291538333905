.root {
  color: var(--palette-primary-700);
  text-decoration: underline;
}

.icon {
  padding-left: calc(0.5 * var(--mini-unit));
  padding-bottom: 2px;
  text-decoration: none;
}
