.name {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-3);

  color: var(--palette-text-500);
}

.body {
  padding-left: var(--spacing-2);
}

.title {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-2);

  padding-bottom: var(--spacing-1);
  padding-right: var(--spacing-1);

  line-height: 1.285;

  color: var(--palette-text-500);
}

.section {
  padding-bottom: var(--spacing-1);
}

.marker {
  margin-right: var(--spacing-1);
}

.detailsDivider {
  border-top: 1px solid $colors-grey-100;
}
