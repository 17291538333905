$moderateCardButtonOutlineRejectColor: var(--palette-error-500);

.root {
  border: 1px solid $moderateCardButtonOutlineRejectColor;
  box-sizing: border-box;
  border-radius: var(--round-corners);
  width: 65px;
  height: 50px;
  position: relative;
  color: $moderateCardButtonOutlineRejectColor;
  &:not(:disabled):active {
    background-color: $moderateCardButtonOutlineRejectColor;
    color: var(--palette-text-000);
  }
}

.open {
  background-color: var(--palette-error-400);
  color: var(--palette-text-000);
}

.readOnly {
  background-color: transparent;
  border-color: $colors-grey-300);
  color: $colors-grey-300);

  &:hover {
    cursor: not-allowed;
  }
}

.readOnly {
  background-color: transparent;
  border-color: $colors-grey-300;
  color: $colors-grey-300;

  &:hover {
    cursor: not-allowed;
  }
}

.invert {
  background-color: $moderateCardButtonOutlineRejectColor;
  color: var(--palette-text-000);
  border-color: $moderateCardButtonOutlineRejectColor;
}

.xIcon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: inherit;
}

.arrowIcon {
  position: absolute;
  left: 75%;
  top: 25%;
  transform: translate(-50%, 25%);
}
