$single-moderate-background: var(--palette-grey-100);
$single-moderate-bar: $colors-teal-900;
$single-moderate-bar-text: var(--palette-text-000);

.background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: $single-moderate-background;
}

.main {
  margin: calc(2 * var(--mini-unit)) 0 calc(4 * var(--mini-unit)) 0;
  display: flex;
  justify-content: center;
}

.subBar {
  height: calc(4 * var(--mini-unit));
  background-color: $single-moderate-bar;
  margin-top: -1px;
}

.subBarBegin {
  position: absolute;
  left: 0;
  font-family: var(--font-family-primary);
  text-decoration: none;
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-2);
  text-transform: uppercase;
  color: $single-moderate-bar-text;
}

.subBarTitle {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-3);
  text-transform: uppercase;
  color: $single-moderate-bar-text;
}
