$moderateCardButtonOutlineApproveColor: var(--palette-success-500);

.root {
  border: 1px solid $moderateCardButtonOutlineApproveColor;
  box-sizing: border-box;
  border-radius: var(--round-corners);
  width: 65px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $moderateCardButtonOutlineApproveColor;
  &:not(:disabled):active {
    background-color: $moderateCardButtonOutlineApproveColor;
    color: var(--palette-text-000);
  }
}

.readOnly {
  background-color: transparent;
  border-color: $colors-grey-300);
  color: $colors-grey-300);

  &:hover {
    cursor: not-allowed;
  }
}

.readOnly {
  background-color: transparent;
  border-color: $colors-grey-300;
  color: $colors-grey-300;

  &:hover {
    cursor: not-allowed;
  }
}

.invert {
  background-color: $moderateCardButtonOutlineApproveColor;
  border-color: $moderateCardButtonOutlineApproveColor;
  color: var(--palette-text-000);
}

.icon {
  font-weight: var(--font-weight-primary-semi-bold);
  color: inherit;
}
