.root {
  display: none;
  background: black;
  position: fixed;
  /* fix to bottom solves some mobile scrolling issues. */
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.active {
  display: block;
  opacity: 0.5;
}
