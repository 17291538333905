.base {
}

.md {
  height: 25px;
  width: 25px;
}

.lg {
  height: 58px;
  width: 59px;
}
