.root {
  width: 500px;
}

.title {
  font-size: var(--font-size-5);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  line-height: 1.2;
}
