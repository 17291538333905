.title {
  font-weight: var(--font-weight-secondary-bold);
  font-family: var(--font-family-secondary);
  color: var(--palette-text-100);
  font-size: var(--font-size-3);
  line-height: 1.285;

  padding-bottom: var(--spacing-3);
}

.username {
  font-size: var(--font-size-6);
  font-weight: var(--font-weight-secondary-bold);
  font-family: var(--font-family-secondary);
  line-height: 1.17;
  color: var(--palette-text-900);

  text-align: center;

  padding-bottom: var(--spacing-5);
}

.copy {
  font-size: var(--font-size-3);
  font-weight: var(--font-weight-primary-regular);
  font-family: var(--font-family-primary);
  line-height: 1.125;
  color: var(--palette-text-500);

  text-align: center;
}

.lockIcon {
  color: var(--palette-text-900);
  padding-bottom: var(--spacing-2);
}

.noPermission {
  font-size: var(--font-size-4);
  font-weight: var(--font-weight-primary-semi-bold);
  font-family: var(--font-family-primary);
  line-height: 0.89;
  color: var(--palette-text-900);

  text-align: center;

  padding-bottom: var(--spacing-5);
}

.contactAdmin {
  padding-top: var(--spacing-3);
}
