.root {
  display: block;
  border: 0;
  padding: 0;

  & > *:last-child {
    margin: 0 !important;
  }
}

.half {
  & > * {
    margin: 0 0 calc(0.5 * var(--mini-unit)) 0 !important;
  }
}
.full {
  & > * {
    margin: 0 0 var(--mini-unit) 0 !important;
  }
}
.oneAndAHalf {
  & > * {
    margin: 0 0 calc(1.5 * var(--mini-unit)) 0 !important;
  }
}
.double {
  & > * {
    margin: 0 0 calc(2 * var(--mini-unit)) 0 !important;
  }
}
.triple {
  & > * {
    margin: 0 0 calc(3 * var(--mini-unit)) 0 !important;
  }
}
.spacing-1 {
  & > * {
    margin: 0 0 var(--spacing-1) 0 !important;
  }
}
.spacing-2 {
  & > * {
    margin: 0 0 var(--spacing-2) 0 !important;
  }
}
.spacing-3 {
  & > * {
    margin: 0 0 var(--spacing-3) 0 !important;
  }
}
.spacing-1 {
  & > * {
    margin: 0 0 var(--spacing-1) 0 !important;
  }
}
.spacing-4 {
  & > * {
    margin: 0 0 var(--spacing-4) 0 !important;
  }
}
.spacing-5 {
  & > * {
    margin: 0 0 var(--spacing-5) 0 !important;
  }
}
.spacing-6 {
  & > * {
    margin: 0 0 var(--spacing-6) 0 !important;
  }
}
.spacing-7 {
  & > * {
    margin: 0 0 var(--spacing-7) 0 !important;
  }
}
.spacing-8 {
  & > * {
    margin: 0 0 var(--spacing-8) 0 !important;
  }
}
.spacing-9 {
  & > * {
    margin: 0 0 var(--spacing-9) 0 !important;
  }
}
