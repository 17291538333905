.table {
  width: 900px;
}

.column {
  white-space: nowrap;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: white;
}
