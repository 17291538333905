.root {
  padding: calc(1.5 * var(--mini-unit)) calc(2 * var(--mini-unit));
}

.text {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-2);
  line-height: 1.285;
}
